
import Vue from "vue";
import { Actions } from "@/store/models";
import { associates, companies, HeaderKeys, removeHeader } from "@/api";
import Logo from "@/components/common/Logo.vue";

export default Vue.extend({
  name: "AccountTransferTerms",
  components: { Logo },
  data() {
    return {
      image: require("/src/assets/Vertical-Travel-quarter-container.jpg"),
      imageBottom: require("/src/assets/bottom-pic-landscape.jpg"),
      showDeclinedView: false,
    };
  },
  computed: {
    terms: function (): string {
      return this.$store.state.terms;
    },
  },
  methods: {
    async accepted() {
      try {
        await associates.updateTerms(this.$store.state.associate?.id, true);
        this.$router.replace({
          path: "/signup/navigate",
          query: { accountTransfer: "true" },
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError);
      }
    },
    async declined() {
      try {
        this.showDeclinedView = true;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError);
      }
    },
    gssiSupport() {
      window.open("https://www.geophysical.com/support", "_blank")?.focus();
    },
    logout() {
      this.$store.dispatch(Actions.Logout);
    },
  },
});
